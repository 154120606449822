// extracted by mini-css-extract-plugin
export var card = "fallbeispiele-module--card--2MNGn";
export var image = "fallbeispiele-module--image--T3pf3";
export var content = "fallbeispiele-module--content--1nz4o";
export var headline = "fallbeispiele-module--headline--1iczo";
export var button = "fallbeispiele-module--button--g2J3M";
export var description = "fallbeispiele-module--description--2EeR-";
export var columnOutsideWrapper = "fallbeispiele-module--column-outside-wrapper--2_g_3";
export var outsideWrapper = "fallbeispiele-module--outside-wrapper--3RTxR";
export var outsideWrapperWithFilter = "fallbeispiele-module--outside-wrapper-with-filter--2hBjq";
export var paginationItem = "fallbeispiele-module--pagination-item--1xO9l";
export var paginationItemActive = "fallbeispiele-module--pagination-item-active--2ysrA";
export var spinAround = "fallbeispiele-module--spinAround--1FXXw";