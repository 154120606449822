import React from "react"
import Layout from "../components/Layout";
import Hero from "../components/ui/Hero/Hero";
import Section from "../components/ui/Section/Section";
import { graphql, Link } from "gatsby";
import {useFlexSearch} from "react-use-flexsearch"
import { GatsbyImage } from "gatsby-plugin-image";
import ButtonLink from "../components/ui/Buttons/ButtonLink";
import * as styles from "./fallbeispiele.module.scss";
import * as stylesListing from '../components/lists/ProductListing.module.scss';
import classnames from 'classnames';
import useBreakpoint from "../utils/useBreakpoint";
import { Breakpoints } from "../utils/Types";
import GraphicDesign from "../components/blocks/GraphicDesign";
import SearchIcon from "../assets/search.icon.svg";
import useGdprIsAllowed from "../components/Gdpr/useGdprIsAllowed";

const Fallbeispiele = ({ data }) => {

    const timeout = React.useRef(null);
    const trackingAllowed = useGdprIsAllowed("Analyse");

    // Search
    const [search, setSearch] = React.useState("");
    const results = useFlexSearch(
        search,
        data.localSearchFallbeispiele.index,
        data.localSearchFallbeispiele.store,
    )

    const doSearch = e => {
        setPage(0);
        setSearch(e.target.value)
    }

    // Listing Breakers
    const [linseTillBreak, setLinesTillBreak] = React.useState(2);
    const listingBreaksElements = [<GraphicDesign />]
    const listingBreaksRows: {[keys in Breakpoints]: number} = {
        mobile: 1,
        tablet: 2,
        desktop: 2,
    }


    // Pagination
    const breakpoint = useBreakpoint();

    const [page, setPage] = React.useState(0);
    const [itemsPerPage, setItemsPerPage] = React.useState(2);
    const itemsPerPageBreakpoints: {[keys in Breakpoints]: number} = {
        mobile: 12,
        tablet: 24,
        desktop: 24,
    }

    React.useEffect(() => {
        if (breakpoint) {
            setLinesTillBreak(listingBreaksRows[breakpoint]);
            setItemsPerPage(itemsPerPageBreakpoints[breakpoint]);
        }
    }, [breakpoint]);

    const changePage = (newPage) => {
        setPage(newPage);
    }


    // SEARCH handling
    const itemsToDisplay = data.allFallbeispieleHJson.edges.filter(({ node }) => {
        if (!search || search === "") {
            return true
        }

        for (const result of results) {
            if (result.url === node.url) {
                return true
            }
        }
        return false
    });


    // PAGINATION handling
    // number of pages extraction
    const pagesArray = [];
    for (let i = 0; i < Math.ceil(itemsToDisplay.length/itemsPerPage); i++) {
        pagesArray.push(i);
    }

    // pagination items
    const itemsToDisplayPage = [];
    itemsToDisplay.forEach((item, index) => {
        const pageStart = itemsPerPage * page;
        const pageEnd = pageStart + itemsPerPage; 
        if (index >= pageStart && index < pageEnd) {
            itemsToDisplayPage.push(item);
        }
    });


     // Track filter and search changes
     React.useEffect(() => {
        if(timeout.current) clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            // track on piwik
            if (_paq && trackingAllowed && search) {
                  _paq.push(['trackSiteSearch',
                      // Search keyword searched for
                      search,
                      // Search category selected in your search engine. If you do not need this, set to false
                      "Fallbeispielsuche",
                      // Number of results on the Search results page. Zero indicates a 'No Result Search Keyword'. Set to false if you don't know
                      itemsToDisplay.length
                  ]);
          }
        }, 1000);
    }, [search]);


    return (
        <Layout crumbs={[{ label: "Home", url: "" }, { label: "Fallbeispiele", url: "fallbeispiele" }]} waveBgColor={"light"} breadcrumbColor="light" hasWave={false}>

            <Hero
                title="Fall&shy;beispiele"
                subtitle="Beispiele unserer Arbeit, unseres Services und wie wir unsere Kunden zufrieden stellen!"
                size="medium"
                color="dark"
                hasWaveBottom={['mobile', 'tablet']}
            />


            <div className={classnames(["section", stylesListing.searchBox])} >
                <div className={"container"}>
                    <div className="columns">
                        <div className="column is-1 is-hidden-touch"></div>
                        <div className="column is-two-thirds-tablet is-three-fifths-desktop">
                            <div className="field has-addons">
                                <div className="control is-expanded has-icons-right">
                                    <input
                                        className={classnames(["input", stylesListing.searchInput])}
                                        type="text"
                                        value={search}
                                        onChange={doSearch}
                                        placeholder="Nach was suchen Sie?"
                                    />
                                    <span className={classnames(["icon", "is-right", stylesListing.searchIconWrapper])}>
                                        <SearchIcon />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Section>
                <div className="columns is-multiline">
                    {itemsToDisplayPage.map(({node}, index) => {
                        const path = "/fallbeispiele/" + node.url;

                        const itemsPerRow = 3 * linseTillBreak;
                        let addition = null;
                        if (index % itemsPerRow  === 0 && index >= itemsPerRow &&  index <= itemsPerRow * listingBreaksElements.length) {
                                // Add the breaker here
                                const Element = React.cloneElement(listingBreaksElements[(index/itemsPerRow) - 1])
                                addition = <div key={"listbreaker-" + index} className={classnames(["column", "is-full", stylesListing.columnOutsideWrapper])} >
                                <div className={stylesListing.outsideWrapper}>
                                    {Element}
                                </div>
                            </div>
                        }

                        return [addition, 
                        <div key={node.url} className="column is-one-third">
                            <div className={styles.card}>
                                <div>
                                    <Link to={path}>
                                        <figure className={classnames(["image", styles.image])}>
                                            <GatsbyImage
                                                image={{...node.listenbild.src.childImageSharp.gatsbyImageData, aspectRatio: 1.6 }}
                                                alt={node.listenbild.alt}
                                                title={node.listenbild.alt} />
                                        </figure>
                                    </Link>
                                </div>
                                <div className={styles.content}>
                                    <Link to={path}>
                                        <p className={classnames(["title", styles.headline])}>{node.name}</p>
                                    </Link>
                                    <p className={styles.description}>{node.kurzbeschreibung}</p>
                                </div>
                                
                                <div>
                                    <ButtonLink className={styles.button} to={path} variation="minimal">mehr Erfahren</ButtonLink>
                                </div>
                            </div>
                        </div>
                        ];
                    })}

                    {(itemsToDisplay.length === 0) && <div className="content">
                        <p className={classnames(["title", "is-4"])}>Keine Fallbeispiele verfügbar</p>
                        <p>Für diese Suche haben wir leider keine Fallbeispiele gefunden</p>
                    </div>}
                </div>

                {pagesArray.length > 1 && <div className="content has-text-right">

                        {page >= 0 && <a href="#" onClick={(e) => {changePage(page - 1)}}>
                            <svg width="13" height="13" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.094,0.375l6.145,6.027l-6.145,6.092" style={{fill: "none", stroke:"#1B9DDC",strokeWidth: "0.75px", transform: "rotate(180deg) translate(-19px, -13px) "}} />
                            </svg>   
                        </a>}
                        {pagesArray.map(pageNumber => <a 
                            key={"page-"+pageNumber} 
                            href={"#"} 
                            className={classnames([stylesListing.paginationItem], (page === pageNumber) ? stylesListing.paginationItemActive : "")} 
                            onClick={(e) => {changePage(pageNumber)}}
                        > 
                            {(page === pageNumber) ? "Seite " : ""}
                            {pageNumber + 1} 
                        </a>)}

                        {page  !== pagesArray.length - 1 && pagesArray.length >= 2 && <a href="#" onClick={(e) => {changePage(page + 1)}}>
                            <svg width="13" height="13" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.094,0.375l6.145,6.027l-6.145,6.092" style={{fill: "none", stroke:"#1B9DDC",strokeWidth: "0.75px", transform: "translate(-13px, 0) "}} />
                            </svg>   
                        </a>}
                    </div>}
            </Section>

        </Layout>
    );}


export const pageQuery = graphql`query IndexFallbeispieleQuery {
  allFallbeispieleHJson(sort: {order: DESC, fields: published}) {
    edges {
      node {
        listenbild {
          alt
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        url
        pdf
        name
        kurzbeschreibung
        weight
      }
    }
  }
  localSearchFallbeispiele {
    index
    store
  }
}
`

export default Fallbeispiele;